

.contents-left {
  flex-basis: 50%;
}

.header-title {
  float: left;
  margin-top: .6em;
  margin-left: 1em;
}

.header-title > a {
  text-decoration: none; /* no underline */
  color: black;
  font-weight: 500;
  font-size: 1.8em;
}

.contents-right {
  position: relative;
  vertical-align: center;
  flex-basis: 50%;
}

.connect-container-connected {
  float: right;
  top: 50%;
  margin-right: 1.5em;
  margin-top: .1em;
}

.connect-container-disconnected {
  float: right;
  top: 50%;
  margin-right: 2em;
  margin-top: .6em;
  margin-bottom: auto;
}

.header-container {
  display: flex;
  width: min(100%,800px);
  margin: .5em auto auto auto;
  height: 60px;
}
