.strava-login-field {
    margin: 1em;
}

.map-container {
    margin: 2em .5em;
}

.map-view {
    font-size: 1.1em;
}

.call-to-action {
    margin: .5em;
}